var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-project-vacant-posession-table"},[_c('h3',{staticClass:"m-2"},[_vm._v("Vacant Posession List")]),_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.vacantPossesionTableColumns,"rows":_vm.vacantPossesionTableData,"totalRows":_vm.vacantPossesionTablePagination.total,"isLoading":_vm.isLoading,"pagination-options":{
      enabled: true,
      mode: 'pages',
      perPage: 30,
      perPageDropdown: _vm.perPageOptions,
      dropdownAllowAll: false
    },"sort-options":{
      enabled: false
    }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.toVacantPossesionDetail},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('button',{staticClass:"btn main bordered ml-1",attrs:{"title":"Confirm Handover"},on:{"click":function($event){return _vm.completeVP(props.row.id)}}},[_c('i',{staticClass:"fas fa-key mr-1"}),_vm._v(" Confirm ")])]):(
          props.column.field == 'assignedAgent' && !_vm._.isEmpty(props.row.agent)
        )?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('p',[_vm._v(_vm._s(props.row.agent.name))]),_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-phone mr-1"}),_vm._v(_vm._s(_vm.$getUserPhoneNumber(props.row.agent.user))+" ")]),_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-envelope mr-1"}),_vm._v(_vm._s(props.row.agent.user.email)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('spinner')],1)]),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no ready vacant possession."}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }