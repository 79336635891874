<template>
  <div class="manage-project-sales-vp">
    <div class="container fluid">
      <vacant-possession-table
        v-if="!_.isEmpty(API)"
        :getPendingVPListAPI="API.getPendingVPList"
        :completeVPAPI="API.completeVP"
        :detailRouteName="getDetailRouteName()"
      ></vacant-possession-table>
    </div>
  </div>
</template>

<script>
import VacantPossessionTable from "@/components/GlobalComponents/Shared/ProjectSales/VacantPossession/VacantPossessionTable";
import { projectVacantPossession as vpAPI } from "@/api";

export default {
  components: {
    VacantPossessionTable
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return { vpAPI, API: {} };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initAPI();
  },
  methods: {
    initAPI() {
      this.API = vpAPI.getProjectVacantPossessionAPI(this.isAssigned);
    },
    getDetailRouteName() {
      return this.isAssigned
        ? "ManageAssignedProjectVacantPossessionDetails"
        : "ManageProjectVacantPossessionDetails";
    }
  }
};
</script>

<style lang="scss">
</style>

