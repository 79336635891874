<template>
  <div class="manage-project-vacant-posession-table">
    <h3 class="m-2">Vacant Posession List</h3>

    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
      :columns="vacantPossesionTableColumns"
      :rows="vacantPossesionTableData"
      :totalRows="vacantPossesionTablePagination.total"
      :isLoading="isLoading"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: perPageOptions,
        dropdownAllowAll: false
      }"
      :sort-options="{
        enabled: false
      }"
      @on-row-click="toVacantPossesionDetail"
    >
      <div slot="loadingContent">
        <div class="d-flex justify-content-center">
          <spinner></spinner>
        </div>
      </div>
      <div slot="emptystate">
        <no-data message="There is no ready vacant possession."></no-data>
      </div>
      <div slot="table-actions" class="p-2"></div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'" @click.stop>
          <button
            class="btn main bordered ml-1"
            title="Confirm Handover"
            @click="completeVP(props.row.id)"
          >
            <i class="fas fa-key mr-1"></i>
            Confirm
          </button>
        </span>

        <!-- ======================== AGENT INFO ======================= -->
        <span
          v-else-if="
            props.column.field == 'assignedAgent' && !_.isEmpty(props.row.agent)
          "
          @click.stop
        >
          <p>{{ props.row.agent.name }}</p>
          <span class="d-flex align-items-center">
            <i class="fas fa-phone mr-1"></i
            >{{ $getUserPhoneNumber(props.row.agent.user) }}
          </span>
          <span class="d-flex align-items-center">
            <i class="fas fa-envelope mr-1"></i>{{ props.row.agent.user.email }}
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [vueGoodTable],
  props: {
    getPendingVPListAPI: {
      type: Function,
      required: true
    },
    completeVPAPI: {
      type: Function,
      required: true
    },
    detailRouteName: {
      type: String,
      default: "ManageProjectVacantPossessionDetails"
    }
  },
  data: function () {
    return {
      vacantPossesionTableData: [],
      vacantPossesionTablePagination: {},
      vacantPossesionTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          width: "150px"
        },
        {
          label: "Plot",
          field: "projectPurchaseReservation.unitPlot"
        },
        {
          label: "Project",
          field: "project.name"
        },
        {
          label: "Buyer Name",
          field: "projectPurchaseReservation.purchaserName1"
        },

        {
          label: "Buyer Email",
          field: "projectPurchaseReservation.email"
        },
        {
          label: "Buyer Hp. Contact",
          field: "projectPurchaseReservation.phoneContact"
        },
        {
          label: "Agent",
          field: "assignedAgent"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      filter: {
        "name[partial]": ""
      },

      isLoading: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllVacantPossesions();
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllVacantPossesions();
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllVacantPossesions().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toVacantPossesionDetail(param) {
      let vacantPossesionId = param.row.id;
      this.$router.push({
        name: this.detailRouteName,
        params: {
          id: vacantPossesionId
        }
      });
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllVacantPossesions();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllVacantPossesions();
    },
    // ========================== API Related =========================
    async getAllVacantPossesions() {
      this.isLoading = true;
      try {
        // let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.getPendingVPListAPI("Pending", {
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });

        this.vacantPossesionTableData = this._.cloneDeep(data.data);
        this.vacantPossesionTablePagination = this._.cloneDeep(
          data.meta.pagination
        );
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get vacant possession list. Please try again later."
        });
      }
    },
    async completeVP(id) {
      let confirm = await this.$confirm({
        title: "Complete Vacant Possession",
        message:
          "Are you sure? This will complete the sales process as this is the last step for it."
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.completeVPAPI(id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Vacant possession has beeen completed successfully."
          });

          await this.getAllVacantPossesions();
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to complete vacant possession. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
